import React from "react";
import "./About.css";

const About = () => {
  return (
    <section id="section2" className="about">
      <div className="about-content">
        <div className="about-title">Crafting Tomorrow's Solutions</div>
        <div className="about-subtitle">
          SODA transforms visionary concepts into robust, innovative realities.
          Our master architects design and build sophisticated technological
          ecosystems, propelling industries forward.
        </div>
        <a href="#section6">
          <button className="hero-button">Contact Us</button>
        </a>
      </div>
    </section>
  );
};

export default About;
