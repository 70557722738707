export const servicesData = [
  {
    title: "AI Model Development",
    description:
      "Custom development of AI models tailored to specific industry needs, including deep learning, reinforcement learning, and neural networks. Our models are designed to integrate seamlessly with third-party applications, providing advanced capabilities in pattern recognition, data analysis, and decision-making.",
  },
  {
    title: "Software R&D",
    description:
      "Comprehensive research and development services for software solutions across various industries. We specialize in agile development, creating highly secure, scalable, and robust applications that address complex technical challenges and meet specific client requirements.",
  },
  {
    title: "Cybersecurity Solutions",
    description:
      "Design and implementation of cutting-edge cybersecurity systems, with a focus on AI-powered threat detection and advanced encryption technologies. Our expertise includes developing real-time log analysis tools and secure messaging platforms, ensuring data integrity across various digital environments.",
  },
  {
    title: "Autonomous Systems",
    description:
      "Development of AI-driven technologies for autonomous systems, including selective attention technology, optical and Lidar signal fusion, and low-power processing algorithms. Our solutions enhance the safety and efficiency of autonomous vehicles and other automated systems.",
  },
  {
    title: "Medical Technology",
    description:
      "Innovative solutions in the Med Tech industry, including AI-based diagnostic tools, smart medical devices, and advanced image processing. We focus on improving patient outcomes through early detection, accurate diagnostics, and remote monitoring capabilities.",
  },
  {
    title: "Machine Vision",
    description:
      "Development of advanced machine vision technologies for various industries. Our solutions include image recognition, object tracking, and quality control systems, enhancing automation and precision in manufacturing, healthcare, and other fields.",
  },
  {
    title: "IoT Integration",
    description:
      "Integration of IoT devices across various platforms, from business applications to military-grade systems. Our services include the development of computer vision algorithms, GPS intelligence systems, and real-time data analysis to improve operational efficiency and decision-making.",
  },
  {
    title: "Systems Architecture",
    description:
      "Design and optimization of complex IT systems, ensuring that all components work together seamlessly. Our expertise in systems architecture includes cloud infrastructure, network design, and integration of emerging technologies for maximum efficiency.",
  },
  {
    title: "UX/UI Design",
    description:
      "Creating intuitive and engaging user experiences through thoughtful UX/UI design. Our designs are user-centric, focusing on enhancing usability and accessibility, while aligning with business goals and branding strategies.",
  },
  {
    title: "Data Tagging for AI Model Creation",
    description:
      "Specialized data tagging services to improve AI model accuracy. We provide high-quality annotations and tagging for various data types, ensuring that AI models are trained on precise and relevant datasets.",
  },
  {
    title: "Defense Technologies",
    description:
      "Development of mission-critical defense technologies, including AI models for radar and sound signature classification, and advanced systems for non-GPS navigation. Our solutions enhance national security by providing precise, real-time data for operational systems in high-stakes environments.",
  },
  {
    title: "Consulting & Advisory",
    description:
      "Expert consulting services to help startups and established companies develop their IP assets, including patent portfolio management and strategic technology planning. We provide guidance on software development, AI integration, and cybersecurity strategies.",
  },
];
