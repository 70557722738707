import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import whiteLogo from "../../assets/images/white-soda.png";
import { useScroll } from "../../context/ScrollContext";

const Navbar: React.FC = () => {
  const { scrollPosition } = useScroll();
  const isScrolledPastMarquee = scrollPosition > 300;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 700) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className={`navbar ${isScrolledPastMarquee ? "navbar-scrolled" : ""}`}>
      <div ref={scrollRef} className="navbar-left">
        <a href="#section4" className="desktop-only">
          About Us
        </a>
        <a href="#expertise" className="desktop-only">
          Services
        </a>
      </div>
      <div className="navbar-center">
        <div className="navbar-logo-container">
          <img
            src={whiteLogo}
            className={`navbar-logo-img ${
              isScrolledPastMarquee ? "logo-small" : ""
            }`}
            alt="Soda Logo"
          />
          <div
            className={`navbar-logo-text ${
              isScrolledPastMarquee ? "scrolled" : ""
            }`}
          >
            Software Outsourcing Development & Architecture LTD.
          </div>
        </div>
      </div>
      <div className="navbar-right">
        <a href="#section6" className="desktop-only">
          Contact
        </a>
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className={`hamburger-icon ${isMenuOpen ? "open" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className={`navbar-menu ${isMenuOpen ? "open" : ""}`}>
        <a href="#section4" onClick={() => setIsMenuOpen(false)}>
          About Us
        </a>
        <a href="#expertise" onClick={() => setIsMenuOpen(false)}>
          Services
        </a>
        <a href="#section6" onClick={() => setIsMenuOpen(false)}>
          Contact
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
