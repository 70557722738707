import React, { useState } from "react";
import { TextField, Button, Container, Grid, Typography } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [status, setStatus] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert("Please complete the reCAPTCHA verification.");
      return;
    }

    setStatus("Sending...");

    try {
      const response = await fetch("https://contact.soda-dev.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, recaptchaToken }),
      });

      if (response.ok) {
        alert("Message sent successfully!");
        setStatus("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setStatus("Failed to send message.");
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus("An error occurred while sending the message.");
      alert("An error occurred while sending the message.");
    }
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token || "");
  };

  return (
    <section id="section6" className="contact">
      <Container maxWidth="sm">
        <Typography variant="h4" component="div" className="section-title">
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                required
                InputProps={{
                  style: { backgroundColor: "#ffffff" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                type="email"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                required
                InputProps={{
                  style: { backgroundColor: "#ffffff" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                name="phone"
                fullWidth
                value={formData.phone}
                onChange={handleChange}
                InputProps={{
                  style: { backgroundColor: "#ffffff" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="message"
                fullWidth
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                InputProps={{
                  style: { backgroundColor: "#ffffff" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div tabIndex={-1}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                  onChange={handleRecaptchaChange}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="button-container">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Submit Now !
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>

      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.799639055007!2d34.91420854966755!3d32.51792932602181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d0dc1e3ea115d%3A0xe872a76760d9fd11!2z157XqNeb15Yg15PXldeTINeQ15XXqCDXoten15nXkdeQ!5e1!3m2!1sen!2sil!4v1724269190986!5m2!1sen!2sil"
          width="100%"
          height="300px"
          loading="lazy"
          tabIndex={-1}
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;
